import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { axiosInstance } from '../../services/axios-instance';

const FloatingVideo = () => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isLiveOnline, setIsLiveOnline] = useState(true);
  const [liveLink, setLiveLink] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const videoContainerRef = useRef(null);
  const dragStartPosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const fetchLiveStatus = async () => {
      try {
        const response = await axiosInstance.get('/play');
        setIsLiveOnline(response.data.online);
        setLiveLink(response.data.link);
      } catch (error) {
        console.error('Erro ao buscar status da live:', error);
      }
    };

    fetchLiveStatus();
  }, []);

  useEffect(() => {
    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = e => {
      if (isDragging && !isMinimized) {
        const deltaX = e.clientX - dragStartPosition.current.x;
        const deltaY = e.clientY - dragStartPosition.current.y;

        videoContainerRef.current.style.bottom = `${
          parseFloat(videoContainerRef.current.style.bottom) - deltaY
        }px`;
        videoContainerRef.current.style.right = `${
          parseFloat(videoContainerRef.current.style.right) - deltaX
        }px`;

        dragStartPosition.current = { x: e.clientX, y: e.clientY };
      }
    };

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging, isMinimized]);

  const handleMouseDown = e => {
    setIsDragging(true);
    dragStartPosition.current = { x: e.clientX, y: e.clientY };
  };

  const handleToggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const containerStyle = {
    position: 'fixed',
    bottom: '1px',
    right: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    zIndex: 1000,
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  const videoStyle = {
    width: isMinimized ? '85px' : '400px',
    height: isMinimized ? '65px' : '300px',
    border: '2px solid white',
    borderRadius: '8px',
    overflow: 'hidden',
    transition: 'width 0.3s, height 0.3s',
    marginBottom: isMobile ? '70px' : '0px',
  };

  const minimizeButtonStyle = {
    marginBottom: isMobile ? (isMinimized ? '70px' : '10px') : '10px',
    backgroundColor: 'red',
    borderRadius: '8px',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1001,
    position: 'relative',
    minWidth: '30px',
  };

  const buttonTextStyle = {
    color: 'white',
  };

  const fixedMessageStyle = {
    position: 'fixed',
    bottom: isMobile ? '70px' : '10px',
    right: '10px',
    backgroundColor: 'red',
    borderRadius: '8px',
    padding: '5px 10px',
    display: isMinimized ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1001,
    minWidth: '200px',
  };

  return (
    <>
      {isLiveOnline && (
        <>
          <div style={fixedMessageStyle} onClick={handleToggleMinimize}>
            <span style={buttonTextStyle}>Estamos ao vivo, clique aqui e assista!</span>
          </div>
          <div ref={videoContainerRef} style={containerStyle} onMouseDown={handleMouseDown}>
            {!isMinimized && (
              <div style={minimizeButtonStyle} onClick={handleToggleMinimize}>
                <span style={buttonTextStyle}>-</span>
              </div>
            )}
            {!isMinimized && (
              <div style={videoStyle}>
                <iframe
                  width="100%"
                  height="100%"
                  src={`${liveLink}&autoplay=1&mute=1`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Floating Video"
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export { FloatingVideo };
